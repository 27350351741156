import { Component } from 'react';

class App extends Component {
  render() {
    const {
      location,
    } = this.props;

    window.location.href = `https://memopin.io/app${location.pathname}`;

    return '';
  }
}

export default App;
